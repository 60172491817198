import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { imageBase } from '../../api/client';
import { calcDaysFromNow, tagsDate } from '../../helpers/dataHelper';
import useSearchParams from '../../hooks/useSearchParams';
import { QueryToolDetailsMapped } from '../../redux/slices/tools/toolsInterface';
import { productToolsFetching } from '../../redux/slices/tools/toolsSlice';
import { getUserAbilities } from '../../redux/slices/users/usersSlice';
import { Card } from '../../styled/components';
import ActiveSwitchFilter from '../filters/ActiveSwitchFilter';
import ManufacturerFilter from '../filters/ManufacturerFilter';
import OwnerTypeFilter from '../filters/OwnerTypeFilter';
import SearchFilter from '../filters/SearchFilter';
import StatusFilter from '../filters/StatusFilter';
import ToolsTypeFilter from '../filters/ToolsTypeFilter';
import Table from '../table/Table';
import { ToolsListType } from './interface';

export const ToolsList: React.FC<ToolsListType> = ({
  items,
  itemsExport,
  title,
  headerAddon,
  withFilters,
  paginationControl,
  pagination,
  dense,
  withAssignedTo = true,
  withActive,
  withTypeFilter,
  withProduct,
  withManufacturer,
  getExportData,
}: ToolsListType) => {
  const itemsFetching = useSelector(productToolsFetching);
  const theme = useTheme();
  const abilities = useSelector(getUserAbilities);
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const [, setURLParams] = useSearchParams();

  const handleFiltersClear = useCallback(() => {
    setURLParams({ clearAll: true });
  }, [setURLParams]);

  const abilityStock = abilities.can('Stock');
  const feature = abilities.can('Container') ? 'containers' : 'items';

  const columns = useMemo(
    () => [
      ...(isSmUp
        ? [
            {
              accessor: 'image_key',
              Header: 'Image',
              Cell: ({ row }: { row: { original: QueryToolDetailsMapped } }) => (
                <Link to={`/${row.original.product_type_id === 2 ? 'stock' : 'tools'}/items/${row.original.item_id}/details`}>
                  {row.original.image_key && (
                    <img src={`${imageBase(row.original.image_key)}?height=36`} key={row.original.item_id} alt={row.original.product_name} />
                  )}
                </Link>
              ),
              disableSortBy: true,
              width: 50,
              style: { paddingTop: 2, paddingBottom: 2, textAlign: 'center' },
              disableExport: true,
            },
          ]
        : []),

      {
        Header: feature === 'containers' ? 'Container' : 'Tool',
        accessor: 'identifier',
        Cell: ({ row }: { row: { original: QueryToolDetailsMapped } }) => (
          <Link to={`/${row.original.product_type_id === 2 ? 'stock' : 'tools'}/items/${row.original.item_id}/details`}>{row.original.identifier}</Link>
        ),
        ...(withFilters && { Filter: ActiveSwitchFilter }),
      },
      ...(withProduct
        ? [
            {
              Header: 'Product',
              accessor: 'product_name',
              ellipsis: true,
              style: { paddingLeft: 0 },
              Cell: ({ row }: { row: { original: QueryToolDetailsMapped } }) => (
                <Link to={`/tools/products/${row.original.product_id}/details`}>{row.original.product_name}</Link>
              ),
            },
          ]
        : []),
      ...(withManufacturer && feature !== 'containers'
        ? [
            {
              Header: 'Manufacturer',
              accessor: 'manufacturer_name',
              Cell: ({ row }: { row: { original: QueryToolDetailsMapped } }) => (
                <Link to={`/tools/manufacturers/${row.original.manufacturer_id}/details`}>{row.original.manufacturer_name}</Link>
              ),
              ...(withFilters && { Filter: ManufacturerFilter }),
            },
          ]
        : []),
      ...(withAssignedTo && feature !== 'containers'
        ? [
            {
              Header: 'Assigned To',
              accessor: 'owner_name',
              Cell: ({ row }: { row: { original: QueryToolDetailsMapped } }) => {
                const linkByName = {
                  Vehicle: `/company/vehicles/${row.original.owner_id}/details`,
                  Operator: `/company/users/${row.original.owner_id}/details`,
                  Room: `/company/rooms/${row.original.owner_id}/details`,
                  Site: `/company/rooms/${row.original.owner_id}/details`,
                };

                return row.original.owner_type_name &&
                  linkByName[row.original.owner_type_name] &&
                  row.original.owner_id !== '00000000-0000-0000-0000-000000000000' ? (
                  <Link to={linkByName[row.original.owner_type_name]}>{row.original.owner_name}</Link>
                ) : (
                  row.original.owner_name
                );
              },
              ...(withFilters && { Filter: OwnerTypeFilter }),
            },
          ]
        : []),
      {
        Header: 'Status',
        accessor: 'scan_status_name',
        sortBy: 'scan_status',
        ...(withTypeFilter && !withFilters && abilityStock && { Filter: ToolsTypeFilter }),
      },
      {
        Header: 'Last Seen By',
        accessor: 'payload_warehouse_name',
      },
      {
        Header: 'Last Seen Location',
        accessor: 'tracking_site_name',
        ellipsis: true,
        Cell: ({ row }: { row: { original: QueryToolDetailsMapped } }) => {
          return row.original.tracking_site_name ? (
            <Link to={`/customers/job-sites/${row.original.tracking_site_id}/details`}>{row.original.tracking_site_name}</Link>
          ) : (
            row.original.tracking_site_name
          );
        },
        ...(withFilters && { Filter: StatusFilter, filterProps: { showAll: true } }),
      },
      {
        Header: 'Last Seen At',
        accessor: 'processed_at',
        Cell: ({ row }: { row: { original: QueryToolDetailsMapped } }) => (row.original.processed_at ? tagsDate(row.original.processed_at) : ''),
      },
      {
        Header: 'Days Onsite',
        accessor: 'days_onsite',
        Cell: ({ row }: { row: { original: QueryToolDetailsMapped } }) => (row.original.processed_at ? calcDaysFromNow(row.original.processed_at) : ''),
        align: 'right',
        width: 140,
      },
      ...(abilities.can('Roll-Off')
        ? [
            {
              Header: 'Born On',
              accessor: 'born_on',
              sortBy: 'born_on',
              Cell: ({ row }: { row: { original: QueryToolDetailsMapped } }) =>
                row.original.born_on ? DateTime.fromISO(row.original?.born_on).toLocaleString(DateTime.DATE_MED) : '',
            },
          ]
        : []),
      ...(withActive
        ? [
            {
              Header: 'Active',
              accessor: 'activeMapped',
              sortBy: 'active',
              ...(withFilters && { Filter: SearchFilter, filterOrder: -1 }),
            },
          ]
        : []),
    ],
    [withAssignedTo, withActive, withFilters, isSmUp, withTypeFilter, withProduct, withManufacturer, abilityStock, feature, abilities],
  );

  return (
    <Card>
      <Table
        columns={columns}
        data={items?.result}
        dataExport={itemsExport?.result}
        title={title}
        loading={itemsFetching}
        headerAddon={headerAddon}
        {...(withFilters && { showDefaultFilters: true, onFiltersClear: handleFiltersClear })}
        paginationControl={paginationControl}
        pagination={pagination}
        {...(dense && { size: 'small', noGlobalFilter: true })}
        {...(withTypeFilter && { hideClearFilters: true })}
        getExportData={getExportData}
        exportFileName="Items"
      />
    </Card>
  );
};
